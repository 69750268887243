<template>
  <div>
    <PageLoader :storage="appLoading" />
    <v-layout wrap justify-start pt-3 px-2>
      <v-flex text-left xs12 sm9 md10>
        <span class="itemHeading1"> Notifications </span>
      </v-flex>
    </v-layout>
    <v-layout wrap pt-4 px-4>
      <v-flex xs12 v-if="notifications.length > 0">
        <template v-for="item in notifications">
          <v-card
            :color="item.status == 'New' ? '#ededed' : '#FFF'"
            class="mt-2"
            :elevation="0"
            :key="item._id"
            @click="routeType(item)"
          >
            <v-layout pa-1 wrap>
              <v-flex
                md1
                sm1
                style="border: 1px black"
                align-self-center
                text-center
              >
                <v-icon color="#000">mdi-bell</v-icon>
              </v-flex>

              <v-flex md9 sm9>
                <v-layout wrap>
                  <v-flex xs12 text-left>
                    <span class="itemKey">{{ item.title }}</span
                    ><br />
                    <span class="itemValue">
                      {{ item.message }}
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex md2 sm2 align-self-center>
                <span class="itemValue1 pr-3">
                  {{ timeSince(item.createddate) }}
                </span>
                <v-icon v-if="item.status == 'New'" size="15" color="#5aa9ff"
                  >mdi-checkbox-blank-circle</v-icon
                >
              </v-flex>
            </v-layout>
          </v-card>
        </template>
      </v-flex>
      <v-flex v-else py-2>
        <span class="itemValue">No Notifications Found !! </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout wrap justify-center>
          <v-flex xs12 pb-4 v-if="notifications && notifications.length > 0">
            <v-layout wrap justify-center>
              <v-flex xs10>
                <v-pagination
                  v-model="currentPage"
                  :length="Pagelength"
                  circle
                  color="#FF6907"
                  small
                ></v-pagination>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import Socket from "./../../Sockets/socket";

export default {
  data() {
    return {
      activeSection: localStorage.getItem("activeSection") || "hwc",
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      //   users: [],
      pages: 0,
      count: 20,
      // currentPage: this.$route.query.page ? Number(this.$route.query.page) : 1,
      currentPage: 1,
      Pagelength: 0,
      notifications: [],
      notificationData: {},
      unreadNotification: null,
      ishwc: false,
      issarpa: false,
      
    };
  },

  beforeMount() {
    this.getData();
  },
  // computed:{
  //   newData()
  //   {
  //     return this.notificationData
  //   }
  // },
  mounted() {
    this.socketData();
    // console.log(this.notificationData)

    // this.notifications.unshift(this.newData);
    // this.$store.commit("changeNotification", this.unreadNotification);
  },
  computed: {
    appUserrole() {
      return this.$store.state.userRole;
    },
  },
  watch: {
    currentPage() {
      this.getData();
    },
    count() {
      this.getData();
    },
  },
  methods: {
    socketData() {
      Socket.notificationFunction(this); // <---read data
    },
    routeType(item) {
      if (item.status == "New")
        this.changeStatus(item);
        
      if (
        console.log("111111111111111111111111111111111111111111111", item.type),
        item.type === "New Conflict" ||
        item.type === "Self Assign" ||
        item.type === "reportResolve" ||
        item.type === "reportRelease" ||
        item.type === "reportAssignToYou" ||
        item.type === "yourReportAssign" ||
        item.type === "yourReportResolve" ||
        item.type === "yourReportRelease" ||
        item.type === "yourReportClose" ||
        item.type === "reportLocationUpdate" ||
        item.type === "reportTransferRequest"
      ) {
        const userRole = localStorage.getItem("mainRole");
        if (userRole == "official") {
          this.$router.push("/officers/cases/caseDetails/?id=" + item.reportId);
        } else if (userRole == "admin") {
          this.$router.push("/Admin/cases/caseDetails/?id=" + item.reportId);
        } else if (userRole == "EmergencyUser") {
          this.$router.push(
            "/officers/cases/caseDetails/?id=" + item.reportId
          );
        }
      }
    },
    changeStatus(item) {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/notification/markasread/",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: item._id,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.getData();
              // window.location.reload();
              this.appLoading = false;
            }
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getData() {
      if (this.activeSection === "hwc") {
        this.ishwc === true;
      }
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/notification/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          count: this.count,
          page: this.currentPage,
          isHWC: true,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.notifications = response.data.result;
              this.appLoading = false;
              this.Pagelength = response.data.pages;
            }
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    timeSince(time) {
      switch (typeof time) {
        case "number":
          break;
        case "string":
          time = +new Date(time);
          break;
        case "object":
          if (time.constructor === Date) time = time.getTime();
          break;
        default:
          time = +new Date();
      }
      var time_formats = [
        [60, "seconds", 1], // 60
        [120, "1 minute ago", "1 minute from now"], // 60*2
        [3600, "minutes", 60], // 60*60, 60
        [7200, "1 hour ago", "1 hour from now"], // 60*60*2
        [86400, "hours", 3600], // 60*60*24, 60*60
        [172800, "Yesterday", "Tomorrow"], // 60*60*24*2
        [604800, "days", 86400], // 60*60*24*7, 60*60*24
        [1209600, "Last week", "Next week"], // 60*60*24*7*4*2
        [2419200, "weeks", 604800], // 60*60*24*7*4, 60*60*24*7
        [4838400, "Last month", "Next month"], // 60*60*24*7*4*2
        [29030400, "months", 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
        [58060800, "Last year", "Next year"], // 60*60*24*7*4*12*2
        [2903040000, "years", 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
        [5806080000, "Last century", "Next century"], // 60*60*24*7*4*12*100*2
        [58060800000, "centuries", 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
      ];
      var seconds = (+new Date() - time) / 1000,
        token = "ago",
        list_choice = 1;

      if (seconds == 0) {
        return "Just now";
      }
      if (seconds < 0) {
        seconds = Math.abs(seconds);
        token = "from now";
        list_choice = 2;
      }
      var i = 0,
        format;
      while ((format = time_formats[i++]))
        if (seconds < format[0]) {
          if (typeof format[2] == "string") return format[list_choice];
          else
            return (
              Math.floor(seconds / format[2]) + " " + format[1] + " " + token
            );
        }
      return time;
    },
  },
};
</script>